<template>
  <v-form ref="labForm">
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        :style="{'padding-bottom': matrix_height - 30 + 'px'}"
        justify="center"
      >
        <v-col
          md="6"
          xl="5"
        >
          <v-card :loading="loading.lab_result">
            <v-card-title class="d-flex justify-space-between">
              <div>
                Lab Result <span v-if="lab_result.external_id">{{ lab_result.external_id }}</span>
                <method-chip
                  :method="lab_result.method"
                  outlined
                  class="ml-2 mt-n1"
                />
              </div>
              <div>
                <template v-if="lab_result.formulary && lab_result.formulary.id != 1">
                  <v-chip left>
                    <v-icon
                      small
                      class="me-1"
                    >
                      fa-light fa-prescription-bottle-pill
                    </v-icon>
                    {{ lab_result.formulary.name }}
                  </v-chip>
                </template>
                <client-icon
                  v-if="lab_result.client && $auth.check({ clients: ['*', 'view','edit'] })"
                  :key="lab_result.client.uuid"
                  :icon="lab_result.client.icon"
                  :name="lab_result.client.name"
                />
              </div>
            </v-card-title>
            <v-card-text>
              <lab-result
                :lab-result="lab_result"
                :loading="loading.lab_result"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          md="6"
          lg="5"
          xl="4"
        >
          <v-card
            :loading="loading.pdf"
            min-height="800"
          >
            <status-notes
              v-if="['needs review', 'contact lab', 'tech support'].includes(lab_result.status)"
              :status-text="lab_result.status"
              :statuses="lab_result.statuses"
            />
            <v-skeleton-loader
              :loading="loading.pdf"
              type="image"
            >
              <v-responsive>
                <pdf
                  ref="pdf"
                  :src="pdf.src"
                  :page="pdf.page"
                  style="width:100%; max-height:50%"
                  @num-pages="pdf.pages = $event"
                />
              </v-responsive>
              <v-btn
                v-if="pdf.pages > 1"
                :disabled="pdf.page === 1"
                fab
                top
                left
                absolute
                x-small
                class="mt-2"
                @click="pdf.page--"
              >
                <v-icon
                  small
                  class="ml-n1 mt-1"
                >
                  fas fa-fw fa-chevron-left
                </v-icon>
              </v-btn>
              <v-btn
                v-if="pdf.pages > 1"
                :disabled="pdf.page === pdf.pages"
                fab
                top
                left
                absolute
                x-small
                class="mt-2 ml-9"
                @click="pdf.page++"
              >
                <v-icon
                  small
                  class="mt-1"
                >
                  fas fa-fw fa-chevron-right
                </v-icon>
              </v-btn>
            </v-skeleton-loader>
            <status-notes
              v-if="lab_result.status != 'interpreted'"
              :status-text="['quality assurance', 'pending approval']"
              :statuses="lab_result.statuses"
              divider-top
              type="notes"
            />
            <v-divider />
            <v-card-actions v-if="lab_result.interpretation">
              <v-col>
                <v-btn
                  v-if="$auth.check({'lab results': 'interpret'}) && !lab_result.interpretation.approved_at"
                  color="primary"
                  icon
                  outlined
                  title="Edit"
                  class="mr-2"
                  @click="$router.push({
                    name: 'Interpretation',
                    params: {
                      uuid: $route.params.uuid,
                      status: lab_result.status,
                      return: $route.name
                    }
                  })"
                >
                  <i class="fas fa-pencil-alt" />
                </v-btn>
                <flag-for-review
                  :key="`flag:${lab_result.uuid}`"
                  :uuid="lab_result.uuid"
                  load="interpretation"
                  @flagged="(uuid) => {
                    $router.replace({
                      params: { uuid: uuid },
                    })
                    fetchLabResult()
                    fetchPdf()
                  }"
                />
                <v-btn
                  color="blue darken-2"
                  icon
                  outlined
                  title="Download"
                  :href="pdf.src"
                  :download="fileName"
                >
                  <i class="fas fa-file-download fa-lg" />
                </v-btn>
              </v-col>
              <record-lock
                :uuid="$route.params.uuid"
                :record-lock="lab_result.record_lock"
                absolute
                @update:lock="lab_result.record_lock = $event"
              />
              <v-col class="shrink">
                <v-badge
                  :value="!lab_result.interpretation.approved_at && lab_result.interpretation.approval_count"
                  :content="lab_result.interpretation.approval_count"
                  overlap
                  bordered
                  color="tertiary"
                >
                  <v-btn
                    v-if="$auth.check({'interpretations': 'approve'})"
                    color="secondary"
                    :loading="loading.button"
                    :disabled="disable_approve"
                    @click="approveInterpretation(false)"
                  >
                    <v-icon
                      small
                      class="mr-2"
                    >
                      mdi-check
                    </v-icon>
                    {{ lab_result.interpretation.approved_at != null ? 'Approved' : 'Approve' }}
                  </v-btn>
                </v-badge>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-footer
      height="auto"
      class="pa-0"
      inset
      app
      elevation="4"
    >
      <treatment-table
        v-if="lab_result.organisms"
        id="treatment-matrix"
        :key="lab_result.match_hash"
        :source="lab_result.sample_source"
        :organisms="detected_pathogens.length ? detected_pathogens : detected_organisms.filter(x => x.controversial)"
        :resistance="lab_result.resistance_genes?.filter(x => x.result.detected).map(x => x.resistance)"
        :allergies="lab_result.allergies"
        :pregnant="lab_result.pregnant"
        :pediatric="pediatric"
        :formulary="lab_result.formulary"
        width="100%"
        @resize="matrix_height = $event"
      />
    </v-footer>
  </v-form>
</template>
<script>
  export default {
    metaInfo () {
      return {
        title: 'Lab Result ' + this.lab_result.external_id + ' | Interpretation',
      }
    },
    components: {
      ClientIcon: () => import('@/components/ClientIcon.vue'),
      FlagForReview: () => import('@/components/lab_result/FlagForReview.vue'),
      LabResult: () => import('@/components/lab_result/LabResult.vue'),
      MethodChip: () => import('@/components/MethodChip.vue'),
      pdf: () => import('vue-pdf'),
      StatusNotes: () => import('@/components/lab_result/StatusNotes.vue'),
      TreatmentTable: () => import('@/components/interpretation/TreatmentTable.vue'),
      RecordLock: () => import('@/components/lab_result/RecordLock.vue'),
    },
    data: () => ({
      loading: {
        lab_result: false,
        button: false,
        pdf: false,
      },
      valid: true,
      lab_result: {},
      pdf: {
        src: null,
        page: 1,
        pages: null,
      },
      matrix_height: 25,
    }),
    computed: {
      detected_organisms () {
        return this.lab_result.organisms?.filter(x => x.result.detected)
      },
      detected_pathogens () {
        return this.detected_organisms.filter(x => x.significant)
      },
      fileName () {
        var filename
        if (this.lab_result.external_id) {
          filename = this.lab_result.external_id + ' - ' + this.lab_result.sample_type + ' - ' + this.lab_result.patient.first_name + ' ' + this.lab_result.patient.last_name + ' - Arkstone.pdf'
        }
        return filename
      },
      patient_age() {
        return this.$moment(this.lab_result.created_at).diff(this.lab_result.patient.dob, 'years')
      },
      pediatric () {
        return this.patient_age < 18
      },
      disable_approve () {
        return !this.$auth.check({'interpretations': ['*', 'approve']}) ||
          this.lab_result.interpretation.approved_at != null ||
          (this.lab_result.record_lock && this.lab_result.record_lock.user.uuid != this.$auth.user().uuid)
      },
    },
    created () {
      this.fetchLabResult()
      this.fetchPdf()
    },
    methods: {
      approveInterpretation (goBack) {
        this.loading.button = true
        this.axios.post('lab_results/' + this.$route.params.uuid + '/interpretation/approve')
          .then((res) => {
            if (!goBack && res.data.next) {
              this.$router.replace({
                params: { uuid: res.data.next },
              })
              this.fetchLabResult()
              this.fetchPdf()
            } else {
              this.$router.push({ name: 'Lab Results' })
            }
          })
          .catch(error => {
            this.$toast.error(error)
          })
          .finally(() => { this.loading.button = false })
      },
      fetchLabResult () {
        this.loading.lab_result = true
        const promise = this.axios.get('lab_results/' + this.$route.params.uuid)

        return promise.then((response) => {
          this.lab_result = response.data
        })
          .catch(error => {
            this.$toast.error(error)
          })
          .finally(() => { this.loading.lab_result = false })
      },
      fetchPdf () {
        this.loading.pdf = true
        this.axios.get('/lab_results/' + this.$route.params.uuid + '/interpretation/pdf', {
          params: {
            output: 'base64',
            redacted: !this.$auth.check({ patients: 'phi' }),
          },
        })
          .then((res) => {
            this.pdf.src = 'data:application/pdf;base64,' + res.data.report_content
          })
          .catch(error => {
            this.$toast.error(error)
          })
          .finally(() => { this.loading.pdf = false })
      },
    },
  }
</script>
<style lang="scss" scoped>
.inner-text {
  height: 100%;
  width: 100%;
  font-size: 4.5em;
  padding-top: 0.9em;

  span {
    max-width: 100px;
  }
}
</style>
